<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<v-row no-gutters class="pui-form-layout">
				<v-col cols="12">
					<pui-field-set :title="$t('pmstatus.formtitle')">
						<v-row no-gutters>
							<v-col cols="12">
								<v-row no-gutters>
									<v-col cols="12" lg="6">
										<pui-select
											v-if="isSuperAdmin"
											attach="pmorganizationid"
											:label="this.$t('pmstatus.organization')"
											:placeholder="this.$t('pmstatus.phorganization')"
											toplabel
											clearable
											required
											:disabled="formDisabled"
											v-model="model"
											modelName="vluppmorganization"
											:modelFormMapping="{ pmorganizationid: 'pmorganizationid' }"
											:itemsToSelect="organizationItemsToSelect"
											itemValue="pmorganizationid"
											itemText="name"
										></pui-select>
										<!-- <pui-text-field
											v-else
											:label="$t('pmstatus.organization')"
											toplabel
											disabled
											required
											v-model="organizationName"
										></pui-text-field> -->
									</v-col>
								</v-row>
								<v-row no-gutters>
									<v-col cols="12" lg="6">
										<pui-select
											attach="pmdatasourcetypeid"
											:label="this.$t('pmstatus.pmdatasourcetype')"
											:placeholder="this.$t('pmstatus.phdatasourcetype')"
											toplabel
											clearable
											required
											:disabled="!hasOrganization"
											v-model="model"
											modelName="vluppmdatasourcetype"
											:modelFormMapping="{ pmdatasourcetypeid: 'pmdatasourcetypeid' }"
											:itemsToSelect="datasourcetypeItemsToSelect"
											itemValue="pmdatasourcetypeid"
											itemText="name"
											:fixedFilter="pmdatasourcetypeFixedFilter"
										></pui-select>
									</v-col>
									<v-col cols="12" lg="6">
										<pui-select
											attach="pmparameterid"
											:label="this.$t('pmstatus.pmparameter')"
											:placeholder="this.$t('pmstatus.phparameter')"
											toplabel
											clearable
											required
											:disabled="!hasDatasource"
											v-model="model"
											modelName="vluppmparameterdatasourcetype"
											:modelFormMapping="{ pmparameterid: 'pmparameterid' }"
											:itemsToSelect="parameterItemsToSelect"
											itemValue="pmparameterid"
											:itemText="(item) => `${item.name} (${item.symbol})`"
											:fixedFilter="pmparameterFixedFilter"
										></pui-select>
									</v-col>
								</v-row>
								<v-row no-gutters>
									<v-col>
										<pui-field-set :title="$t('pmstatus.status')">
											<status-form-filter
												ref="statusFormFilter"
												:filterProp="filterData"
												:rows="rows"
												:disabled="!hasParameter"
												:maxFilters="maxFilters"
												:showSubgroupBtn="false"
												@change="checkFilter"
											></status-form-filter>
										</pui-field-set>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
						<v-row no-gutters>
							<v-col cols="12">
								<pui-text-area
									v-model="model.description"
									:label="$t('pmstatus.description')"
									maxlength="250"
									toplabel
								></pui-text-area>
							</v-col>
						</v-row>
						<v-row no-gutters>
							<v-col cols="12" md4>
								<pui-checkbox
									:label="$t('pmstatus.disabled')"
									v-model="model.disabled"
									true-value="1"
									false-value="0"
									style="margin-top: -1em !important"
								></pui-checkbox>
							</v-col>
						</v-row>
					</pui-field-set>
				</v-col>
			</v-row>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="checkColor"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import { isSuperAdmin } from '../../api/common';
import StatusFormFilter from '../form/status_form_filter/StatusFormFilter';
export default {
	name: 'pmstatusform',
	mixins: [PuiFormMethodsMixin],
	components: { StatusFormFilter },

	data() {
		return {
			modelName: 'pmstatus',
			model: 'pmstatus',
			isSuperAdmin: false,
			pmdatasourcetypeFixedFilter: {},
			pmparameterFixedFilter: {},
			filterData: [],
			/* filterData: [
				{
					name: 'Good22',
					status: {
						max: {
							op: 'lt',
							data: 50
						}
					},
					color: '#000000'
				},
				{
					name: 'Fair123',
					status: {
						min: {
							op: 'gt',
							data: 51
						},
						max: {
							op: {
								text: this.$t('lessThan'),
								value: 'lt'
							},
							data: 100
						}
					},
					color: '#FFFFFF'
				}
			], */
			maxFilters: 10,
			rows: [
				{
					min: -500,
					max: 2000,
					op: [
						{
							text: this.$t('greaterThan'),
							value: 'gt'
						},
						{
							text: this.$t('greaterEqualThan'),
							value: 'gte'
						}
					]
				},
				{
					min: -300,
					max: 1000,
					op: [
						{
							text: this.$t('lessThan'),
							value: 'lt'
						},
						{
							text: this.$t('lessEqualThan'),
							value: 'lte'
						}
					]
				}
			]
		};
	},
	computed: {
		datasourcetypeItemsToSelect() {
			return [{ pmdatasourcetypeid: this.model.pmdatasourcetypeid }];
			//return this.model.pmorganizationid ? [{ pmdatasourcetypeid: this.model.pmdatasourcetypeid }] : [{}];
		},
		parameterItemsToSelect() {
			return [{ pmparameterid: this.model.pmparameterid }];
			//return this.model.pmdatasourcetypeid ? [{ pmparameterid: this.model.pmparameterid }] : [{}];
		},
		organizationItemsToSelect() {
			return [{ pmorganizationid: this.model.pmorganizationid }];
		},
		organizationName() {
			return this.userProperties['organizationname'];
		},
		hasOrganization() {
			return !!this.model.pmorganizationid;
		},
		hasDatasource() {
			return !!this.model.pmdatasourcetypeid;
		},
		hasParameter() {
			return !!this.model.pmparameterid;
		},
		isUpdateEntry() {
			return typeof this.model == 'object' && !!this.model.pmstatusid;
		}

		/* pmdatasourcetypeFixedFilter() {
			if (this.model.pmorganizationid) {
				return {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'pmorganizationid', op: 'eq', data: this.model.pmorganizationid }]
				};
			}
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'pmorganizationid', op: 'eq', data: null }]
			};
		} */
	},
	watch: {
		'model.pmorganizationid': {
			handler(newValue, oldValue) {
				console.log('debug organization', oldValue, newValue, !!(newValue && oldValue));

				if (newValue && oldValue && newValue != oldValue) {
					this.model.pmdatasourcetypeid = null;
				}
				if (newValue) {
					this.pmdatasourcetypeFixedFilter = {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'pmorganizationid', op: 'eq', data: this.model.pmorganizationid }]
					};
				} else {
					this.pmdatasourcetypeFixedFilter = {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'pmorganizationid', op: 'eq', data: -1 }]
					};
				}
			}
		},
		'model.pmdatasourcetypeid': {
			handler(newValue, oldValue) {
				if (newValue && oldValue) {
					this.model.pmparameterid = null;
				}
				if (newValue) {
					this.pmparameterFixedFilter = {
						groups: [],
						groupOp: 'and',
						rules: [
							{ field: 'pmdatasourcetypeid', op: 'eq', data: newValue },
							{ field: 'pmorganizationid', op: 'eq', data: this.model.pmorganizationid }
						]
					};
				} else {
					this.pmparameterFixedFilter = {
						groups: [],
						groupOp: 'and',
						rules: [
							{ field: 'pmdatasourcetypeid', op: 'eq', data: -1 },
							{ field: 'pmorganizationid', op: 'eq', data: -1 }
						]
					};
				}
				console.log('debug datasourcetype', oldValue, newValue, !!(newValue && oldValue), this.pmparameterFixedFilter);
			}
		},
		'model.color': {
			handler(newValue, oldValue) {
				console.log('debug color', oldValue, newValue);
				if (newValue && typeof newValue == 'object') {
					this.model.color = this.model.color.hex;
				}
			}
		},
		'model.pmstatusid'(val, prev) {
			console.log('watch pmstatusid', prev, val);
			if (val && !prev) {
				this.mapFilterData();
			}
		}
	},
	beforeCreate() {
		if (this.model) {
			this.modelLoaded = true;
		}
	},
	mounted() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);

		if (!this.isSuperAdmin) {
			this.pmdatasourcetypeFixedFilter = {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }]
			};

			this.pmparameterFixedFilter = {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'pmdatasourcetypeid', op: 'eq', data: this.$store.getters.getDatasourcetype[0].pmdatasourcetypeid },
					{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }
				]
			};
		}
		console.log('debug mounted status form', this.model.pmdatasourcetypeid, this.userProperties, this.$store.getters.getDatasourcetype);
	},
	updated() {
		if (this.model) {
			if (!this.modelLoaded || !this.model.pmorganizationid) {
				this.modelLoaded = true;
			}
			if (!this.model.pmorganizationid && !this.isSuperAdmin) {
				this.model.pmorganizationid = this.userProperties['organizationid'];
			}
		}
		//console.log('debug updated status form', this.model.pmdatasourcetypeid, this.pmparameterFixedFilter, this.model);
	},
	methods: {
		checkColor() {
			if (this.$refs.form && this.$refs.form.validate && !this.$refs.form.validate()) {
				this.formValidationErrors = true;
				this.$puiNotify.error(this.$t('pui9.error.form_errors'));
				this.resetAfterSaveActions();
				return;
			}

			const names = this.filterData.map((filter) => filter.name);
			const statusArray = this.filterData.map((filter) => filter.status);
			this.formValidationErrors = false;
			let errorMessage = null;
			names.forEach((name, i) => {
				console.log('name debug', name, parseInt(name), name == parseInt(name));
				if (parseInt(name) == name) {
					this.formValidationErrors = true;
					errorMessage = this.$t('pmstatus.nameError');
				}
				if (statusArray[i].min.op === null && statusArray[i].max.op === null) {
					this.formValidationErrors = true;
					errorMessage = this.$t('pmstatus.rangeError');
				}
			});
			if (this.formValidationErrors) {
				this.$puiNotify.error(errorMessage, this.$t('common.error'));
				this.resetAfterSaveActions();
				return;
			} else {
				const colors = this.filterData.map((filter) => filter.color);

				statusArray.forEach((status) => {
					if (status.min.op && status.min.data === null) status.min.op = null;
					if (status.max.op && status.max.data === null) status.max.op = null;
				});

				this.model.status = JSON.stringify(statusArray);
				this.model.name = JSON.stringify(names);
				this.model.color = JSON.stringify(colors);

				console.log('Before save', names, colors, statusArray);

				this.save();
			}
		},
		checkFilter(val) {
			console.log('check filter', val);
		},
		mapFilterData() {
			console.log('map filter data', this.model.color, this.model.name, this.model.status);

			const colors = JSON.parse(this.model.color);
			const names = JSON.parse(this.model.name);
			const statusArray = JSON.parse(this.model.status);
			console.log('map filter data', colors, names, statusArray);
			colors.forEach((color, i) => {
				this.filterData.push({
					color: color,
					name: names[i],
					status: statusArray[i]
				});
			});

			this.filterData;
		}
	}
};
</script>
